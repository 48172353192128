define("discourse/plugins/discourse-translator/discourse/initializers/extend-for-translate-button", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-translator/discourse/components/language-switcher", "discourse/plugins/discourse-translator/discourse/components/post-menu/toggle-translation-button", "discourse/plugins/discourse-translator/discourse/components/show-original-content", "discourse/plugins/discourse-translator/discourse/components/translated-post"], function (_exports, _pluginApi, _languageSwitcher, _toggleTranslationButton, _showOriginalContent, _translatedPost) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeTranslation(api) {
    const siteSettings = api.container.lookup("service:site-settings");
    if (!siteSettings.translator_enabled) {
      return;
    }
    const currentUser = api.getCurrentUser();
    if (!currentUser && siteSettings.experimental_anon_language_switcher && siteSettings.automatic_translation_target_languages) {
      api.headerIcons.add("discourse-translator_language-switcher", _languageSwitcher.default, {
        before: ["search"]
      });
    }
    if (siteSettings.experimental_inline_translation && (currentUser || siteSettings.experimental_anon_language_switcher)) {
      api.renderInOutlet("topic-navigation", _showOriginalContent.default);
      api.registerCustomPostMessageCallback("translated_post", (topicController, data) => {
        if (new URLSearchParams(window.location.search).get("show") === "original") {
          return;
        }
        const postStream = topicController.get("model.postStream");
        postStream.triggerChangedPost(data.id, data.updated_at).then(() => {
          topicController.appEvents.trigger("post-stream:refresh", {
            id: data.id
          });
        });
      });
    }
    customizePostMenu(api);
  }
  function customizePostMenu(api) {
    api.registerValueTransformer("post-menu-buttons", _ref => {
      let {
        value: dag,
        context: {
          firstButtonKey
        }
      } = _ref;
      dag.add("translate", _toggleTranslationButton.default, {
        before: firstButtonKey
      });
    });

    // the plugin outlet is not updated when the post instance is modified unless we register the new properties as
    // tracked
    api.addTrackedPostProperties("detectedLang", "isTranslating", "isTranslated", "translatedText", "translatedTitle");
    api.renderBeforeWrapperOutlet("post-menu", _translatedPost.default);
  }
  var _default = _exports.default = {
    name: "extend-for-translate-button",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.39.2", api => initializeTranslation(api));
    }
  };
});